import {Component, OnInit} from '@angular/core';
import {TranslationLoaderService} from '../service/translation-loader.service';
import {locale as english} from '../shared/i18n/en';
import {locale as french} from '../shared/i18n/fr';
import {projectsEn} from '../api/projectsEn';
import {projectsFr} from '../api/projectsFr';

import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {
  projects: any[] = projectsEn;
  venobox: any;

  constructor(private translationLoaderService: TranslationLoaderService, private translateService: TranslateService) {
    this.translationLoaderService.loadTranslations(english, french);
    this.translateService.onLangChange.subscribe(() => {
      if (this.translateService.currentLang === 'en') {
        this.projects = projectsEn;
      } else {
        this.projects = projectsFr;
      }
    });
  }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    console.log('after view init');
    this.onMouse('portfolio-link', 'portfolio-img');
    this.onMouse('teamTraveler-link', 'teamTraveler-img');
    this.onMouse('mautic-link', 'mautic-img');
    this.onMouse('pokedex-link', 'pokedex-img');
    this.onMouse('riddle-link', 'riddle-img');
    this.venobox = $('.venobox');
    this.venobox.venobox();
  }

  detailOnClick(project: any) {
    this.projects.filter(item => item.detailIsDisplayed && item.id != project.id).map(elem => elem.detailIsDisplayed = false);
    project.detailIsDisplayed = !project.detailIsDisplayed;
  }

  onMouse(idLink: String, idImage: String) {
    $('#' + idLink).on('mouseenter', function() {
      console.log('on mouseenter');
      $('#' + idImage).css('opacity', '0.3');
      $('#' + idLink).css('opacity', '1');
    }).on('mouseleave', function() {
        $('#' + idImage).css('opacity', '1');
        $('#' + idLink).css('opacity', '0');
      }
    );

    $('#' + idImage).on('mouseenter', function() {
      $('#' + idImage).css('opacity', '0.3');
      $('#' + idLink).css('opacity', '1');
    }).on('mouseleave', function() {
        $('#' + idImage).css('opacity', '1');
        $('#' + idLink).css('opacity', '0');
      }
    );
  }
}
