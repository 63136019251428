<section id="about">
  <div class="container">
    <h2><strong>Hakkımızda</strong></h2>
    <span class="bar-title mb-5"></span>
    <div class="row">
      <div class="col-lg-12  col-md-12 pt-4 pt-lg-0" data-aos="fade-left">
        <h3>Samsun Kiralık Foklift Hizmeti</h3>
        <p class="font-bold">
          Samsun merkez ve ilçelerine foklift kiralama hizmeti için kurulmuş bir şirketiz.
        </p>
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <ul>
              <li>
                <fa-icon [icon]="mapMarker"></fa-icon>
                <strong> Kışla Mahallesi Polatlı Bulvarı Odun pazarı mevkii No:85</strong>
              </li>
              <li>
                <fa-icon [icon]="building"></fa-icon>
                <strong> Samsun/İlkadım</strong>
              </li>
              <li>
                <fa-icon [icon]="email"></fa-icon>
                <strong><a href="mailto:akmanlarforklif@gmail.com">
                  akmanlarforklif@gmail.com</a></strong>
              </li>
              <li>
                <fa-icon [icon]="mobile"></fa-icon>
                <strong><a href="tel:05415257814">
                  05415257814</a></strong>
              </li>
              <li>
                <fa-icon [icon]="mobile"></fa-icon>
                <strong><a href="tel:03622303490">
                  03622303490</a></strong>
              </li>
            </ul>
          </div>

        </div>

      </div>
    </div>
  </div>
</section>
