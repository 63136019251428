<header>
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark">
    <div class="home p-1 rounded">
      <ul class="navbar-nav">
        <li class="nav-item p-1 rounded">
          <a class="nav-link smooth-scroll text-uppercase" href="#"><i class='bx bx-home'></i> Akmanlar Forklift
          </a>
        </li>
      </ul>
    </div>
    <button class="navbar-toggler nav mb-1">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="container ">
      <div style="width: 100%; float: right;" class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav" style=" float: right;">
          <li class="nav-item p-1 rounded">
            <a class="nav-link js-scroll-trigger smooth-scroll text-uppercase" href="#about"><i
              class='bx bx-user'></i> Hakkımızda
            </a>
          </li>
          <li class="nav-item p-1 rounded">
            <a class="nav-link js-scroll-trigger smooth-scroll text-uppercase" href="#portfolio"> <i
              class='bx bx-book-content'></i>Makinalarımız</a>
          </li>
          <li class="nav-item p-1 rounded">
            <a class="nav-link js-scroll-trigger smooth-scroll text-uppercase" href="#references"><i
              class='bx bxs-badge-check'></i> Referanslar</a>
          </li>
          <li class="nav-item p-1 rounded">
            <a class="nav-link js-scroll-trigger smooth-scroll text-uppercase" href="tel:05415257814"><i
              class='bx bx-envelope'></i> 05415257814</a>
          </li>


        </ul>
      </div>
    </div>
  </nav>
</header>
