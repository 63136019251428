export const projectsEn = [
  {
    id: 'teamTraveler',
    name: 'Samsun Forklift Kiralama',
    technologies: 'Android Studio, Java, FireBase, MVP, NoSql',
    images: [
      {
        title: 'Samsum Kiralık Forklift',
        src: 'assets/images/fork1.jpg',
      },
      {
        title: 'Trip',
        src: 'assets/images/d_fork1.jpeg',
      },
      {
        title: 'Housing',
        src: 'assets/images/d_fork2.jpeg',
      },
    ],

  },
];

