<section id="skills">
    <div class="container">
        <h2><strong>Hizmetlerimiz</strong></h2>
        <span class="bar-title mb-5"></span>
        <div class="row mt-2">
            <div class="col-lg-4" data-aos="fade-up " data-aos-anchor-placement="center-bottom" data-aos-delay="300">
                <h5 class="h5"><strong>Forklift Kirama</strong></h5>

            </div>

        </div>
    </div>

</section>
