import {Component, OnInit} from '@angular/core';
import {TranslationLoaderService} from '../service/translation-loader.service';
import {locale as english} from '../shared/i18n/en';
import {locale as french} from '../shared/i18n/fr';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {

  constructor(private translationLoaderService: TranslationLoaderService) {
    this.translationLoaderService.loadTranslations(english, french);
  }

  ngOnInit(): void {
  }

}




